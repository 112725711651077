import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useMemo } from 'react';
import { priceFormatter, numberFormatter } from '../helpers/formatters';
import { EditableTable } from '../helpers/EditableTable';
import { useLazyGetPricesQuery } from '../services/edgeApi';
import { DateSelector } from '../helpers/DateSelector';
import {
  formatPriceSource,
  marketKeyExtractor,
  scenarioActions,
  selectMarkets,
} from '../scenarios/scenarioSlice';
import { toIsoDate } from '../utils/stringUtils';
import { colors } from '../utils/colors';
import { AnnotationWarningIcon, ZoomInIcon } from '@iconicicons/react';
import { UncontrolledTooltip } from 'reactstrap';
import { EditFeedbackPointInput } from '../helpers/EditFeedbackInput';
import { MarketPrice } from './MarketPrice';
import * as plotterSelectors from '../plotter/plotterSelectors';
import { usePanMap } from '../plotter/usePanMap';
import { SpreadsheetModal } from '../helpers/SpreadsheetModal';
import { useDebouncedCallback } from '../helpers/hooks';

/**
 * @typedef {{
 *  POINT: 0,
 *  LOC_NAME: 1,
 *  DESCRIPTION: 2,
 *  BUY_SELL: 3,
 *  MAX_VOLUME: 4,
 *  PRICE: 5,
 * }} MarketSpreadsheetColumns
 */

/**
 *
 * Spreadsheet column indexers
 *
 * @type {MarketSpreadsheetColumns}
 */
const COLUMNS = {
  POINT: 0,
  LOC_NAME: 1,
  DESCRIPTION: 2,
  BUY_SELL: 3,
  MAX_VOLUME: 4,
  PRICE: 5,
};

const REQUIRED_COLUMNS = [COLUMNS.POINT, COLUMNS.BUY_SELL, COLUMNS.PRICE];

/**
 * @type {Record<MarketSpreadsheetColumns, string>}
 */
const COLUMNS_LABELS = {
  [COLUMNS.POINT]: 'Point',
  [COLUMNS.LOC_NAME]: 'Location',
  [COLUMNS.DESCRIPTION]: 'Description',
  [COLUMNS.BUY_SELL]: 'Buy/Sell',
  [COLUMNS.MAX_VOLUME]: 'Max Volume',
  [COLUMNS.PRICE]: 'Market Price',
};

/**
 *
 * @param {{
 *  priceSource: import('../scenarios/scenarioSlice').PriceSource,
 *  simulateSpread: boolean,
 *  optimizationType: import('../scenarios/scenarioSlice').OptimizationType,
 *  editedRows: string[],
 *  onClickEditionWarning: (market: import('../scenarios/scenarioSlice').Market) => void,
 *  isEditing: boolean,
 *  allPoints: import('../scenarios/scenarioSlice').Point,
 *  allNodes: Node,
 *  onLocateNode: (node: Node) => void
 * }} params
 * @return {import('../helpers/EditableTable').EditableTableColumn<import('../scenarios/scenarioSlice').Market>[]}
 */
const createColumns = ({
  simulateSpread,
  optimizationType,
  editedRows,
  onClickEditionWarning,
  isEditing,
  allPoints,
  allNodes,
  onLocateNode,
}) => [
  {
    title: 'Point',
    allowFiltering: true,
    key: 'point',
    type: 'text',
    placeholder: 'Point',
    renderInput: EditFeedbackPointInput,
    displayFunction: (value) => {
      const pointName = allPoints[value]?.loc_name;
      return `#${value} ${pointName}`;
    },
    isEditable: false,
    informedOnCreation: true,
    renderCell: ({ data }) => {
      const pointName =
        Object.keys(allPoints).length > 0
          ? allPoints[data.point]?.loc_name ?? '--'
          : 'Points Loading...';
      const node = allNodes[allPoints[data.point]?.node];
      return (
        <div
          style={{
            maxWidth: '350px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <button
            className="btn btn-light me-2"
            disabled={!node}
            onClick={() => onLocateNode(node)}
          >
            <ZoomInIcon />
          </button>
          <strong className="me-2">
            #{data.point}
            {isEditing && editedRows.includes(marketKeyExtractor(data)) && (
              <>
                <span
                  id={`market-${data.point}-edition-warning`}
                  role="button"
                  onClick={() => onClickEditionWarning(data)}
                >
                  <AnnotationWarningIcon color={colors.matteYellow} />
                </span>
                <UncontrolledTooltip
                  target={`market-${data.point}-edition-warning`}
                >
                  This market has been manually edited and some of its data will
                  not be updated. Click to revert this setting.
                </UncontrolledTooltip>
              </>
            )}
          </strong>
          {pointName}
        </div>
      );
    },
  },
  {
    title: 'TSP',
    allowFiltering: true,
    key: 'pipeline',
    type: 'text',
    placeholder: 'TSP',
    isEditable: false,
    default: '',
    width: 0.06,
  },
  {
    title: 'Description',
    allowFiltering: true,
    key: 'description',
    type: 'text',
    placeholder: 'Description',
  },
  {
    title: 'Buy/Sell',
    allowFiltering: true,
    key: 'buy_sell',
    type: 'select',
    placeholder: 'Select Type',
    selectOptions: [
      { value: 'BOTH', label: 'Both' },
      { value: 'BUY', label: 'Buy' },
      { value: 'SELL', label: 'Sell' },
    ],
    displayFunction: (value) =>
      value ? value.at(0) + value.slice(1).toLowerCase() : '--',
    isEditable:
      ['ALL_PATHS_TO_POINT', 'BEST_PATHS_TO_POINT'].includes(
        optimizationType,
      ) === false,
  },
  {
    title: 'Max Volume',
    key: 'max_volume',
    placeholder: 'Max Volume',
    type: 'number',
    default: 1000000,
    isEditable:
      ['ALL_PATHS_TO_POINT', 'BEST_PATHS_TO_POINT'].includes(
        optimizationType,
      ) === false,
    displayFunction: numberFormatter,
  },
  {
    title: 'Market Price',
    key: 'price',
    placeholder: 'Market Price',
    type: 'number',
    default: 0,
    isEditable: !simulateSpread,
    displayFunction: (value) => {
      if (simulateSpread) {
        return 'Simulated';
      }
      return priceFormatter.format(value);
    },
    renderInput: (renderProps) => {
      if (simulateSpread) {
        return <div>Simulated</div>;
      }
      return <MarketPrice {...renderProps} />;
    },
  },
  {
    title: 'Price Source',
    key: 'price_source',
    type: 'text',
    default: 'USER_DEFINED',
    isEditable: false,
    placeholder: 'User Defined',
    displayFunction: formatPriceSource,
  },
  {
    title: 'Price Explanation',
    key: 'price_explanation',
    type: 'text',
    isEditable: false,
    displayFunction: (value) => (simulateSpread ? 'Simulated spread' : value),
  },
];

/**
 * @typedef {{
 *  preSelect?: import('../scenarios/scenarioSlice').Market[]
 * }} MarketsProps
 * @param {MarketsProps} props
 * @returns {React.FC<MarketsProps>}
 */
export const Markets = (props) => {
  // pass in pointId to filter by certain point and create new ones at that point
  // get selected point id through props or redux selector
  // probably best to pass it in, that way it can be reused when the point is not selected in redux directly
  const { preSelect } = props;
  const componentType = 'markets';

  // set markets in redux that are preselected on first render
  useEffect(() => {
    if (preSelect) {
      dispatch(
        scenarioActions.overwrite({ componentType, components: preSelect }),
      );
    }
  }, [preSelect]);

  // /**
  //  *
  //  * @type {import('../scenarios/scenarioSlice').ScenarioState}
  //  */
  // const { data: scenario, isEditing } = useSelector((state) => state.scenario);

  const _markets = useSelector(selectMarkets);

  /** @type {import('../scenarios/scenarioSlice').ScenarioComponentSettingsAction<"markets">[]} */
  const marketSettingsActions = useSelector(
    (state) => state.scenario.data.settings.markets?.actions || [],
  );

  /** @type {string} */
  const flowDate = useSelector((state) => state.scenario.data.flow_date);

  /** @type {import('../scenarios/scenarioSlice').PriceSource} */
  const priceSource = useSelector(
    (state) => state.scenario.data.settings.price_source,
  );

  const strip = useSelector((state) => state.scenario.data.settings.strip);

  /** @type {boolean} */
  const simulateSpread = useSelector(
    (state) => state.scenario.data.settings.simulate_spread,
  );

  /** @type {number} */
  const deliveryPoint = useSelector(
    (state) => state.scenario.data.settings.optimization_settings?.delivery,
  );

  /** @type {import('../scenarios/scenarioSlice').OptimizationType} */
  const optimizationType = useSelector(
    (state) => state.scenario.data.settings.optimization_type,
  );

  /** @type {import('../scenarios/scenarioSlice').PriceType} */
  const priceType = useSelector(
    (state) => state.scenario.data.settings.price_type,
  );

  /** @type {string} */
  const isEditing = useSelector((state) => state.scenario.isEditing);

  /** @type {[boolean, React.SetStateAction<boolean>]} */
  const [importModalOpen, setImportModalOpen] = useState(false);

  /** @type {React.Ref<import('../helpers/Spreadsheet').SpreadsheetRef<import('react-spreadsheet').CellBase>>} */
  const sheetRef = React.useRef(null);

  /**
   * @type {import('../scenarios/scenarioSlice').Market[]}
   */
  const ignoredMarkets = useMemo(
    () =>
      marketSettingsActions
        .filter((action) => action.type === 'REMOVE')
        .map((action) => action.data) || [],
    [marketSettingsActions],
  );

  const allPoints = useSelector(plotterSelectors.selectAllPoints);
  const allNodes = useSelector(plotterSelectors.selectAllNodes);

  const markets = useMemo(
    () =>
      _markets.map((market) => {
        const point = allPoints[market.point];
        // console.log({ market, point });
        return {
          ...market,
          pipeline: point?.tsp_name ?? '',
        };
      }),
    [_markets],
  );

  const updatedMarkets = useMemo(
    () =>
      marketSettingsActions
        .filter((action) => action.type === 'UPDATE')
        .map((action) => marketKeyExtractor(action.data)) || [],
    [marketSettingsActions],
  );

  const addedMarkets = useMemo(
    () =>
      marketSettingsActions
        .filter((action) => action.type === 'ADD')
        .map((action) => marketKeyExtractor(action.data)) || [],
    [marketSettingsActions],
  );

  const dispatch = useDispatch();
  const panMap = usePanMap();
  const [
    pricesTrigger,
    { isFetching: fetchingPrices, isLoading: loadingPrices },
  ] = useLazyGetPricesQuery();

  const [isAdding, setIsAdding] = useState(false);
  const [showIgnored, setShowIgnored] = useState(false);
  const [marketDate, setMarketDate] = useState(new Date(flowDate));

  /**
   * @type {(data: import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>) => void}
   */
  const onChangeSpreadsheetDebounce = useDebouncedCallback(
    (data) => {
      const newData = data.map((row) => {
        let newRow = [...row];
        if (
          row[COLUMNS.POINT].value !== '' &&
          !isNaN(Number(row[COLUMNS.POINT].value))
        ) {
          newRow[COLUMNS.LOC_NAME].value =
            allPoints[row[COLUMNS.POINT].value]?.loc_name ?? '';
        }
        return newRow;
      });
      sheetRef.current?.setData(newData);
    },
    1000,
    [allPoints],
  );

  useEffect(() => {
    setMarketDate(new Date(flowDate));
  }, [flowDate]);

  const onClickEditionWarning = (market) => {
    dispatch(
      scenarioActions.revertUpdateSettings({
        componentType,
        component: market,
      }),
    );
  };

  const columns = useMemo(
    () =>
      createColumns({
        priceSource,
        simulateSpread,
        optimizationType,
        editedRows: updatedMarkets,
        onClickEditionWarning,
        isEditing,
        allNodes,
        allPoints,
        onLocateNode: (node) => {
          panMap(node, 12);
        },
      }),
    [
      simulateSpread,
      optimizationType,
      updatedMarkets.join(','),
      markets,
      isEditing,
      allNodes,
      allPoints,
    ],
  );

  /**
   * @type {import('../scenarios/scenarioSlice').Market[]}
   */
  const listedMarkets = useMemo(() => {
    if (
      ['ALL_PATHS_TO_POINT', 'BEST_PATHS_TO_POINT'].includes(optimizationType)
    ) {
      return markets.map((market) => {
        return market.point?.toString() === deliveryPoint?.toString()
          ? { ...market, buy_sell: 'SELL' }
          : { ...market, buy_sell: 'BUY' };
      });
    }
    return markets;
  }, [markets, optimizationType, deliveryPoint]);

  const saveBulkEdit = (selection, updateData) => {
    dispatch(
      scenarioActions.bulkUpdate({
        componentType,
        componentIds: selection,
        data: updateData,
      }),
    );
  };

  const saveItem = (original, edited) => {
    dispatch(
      scenarioActions.updateComponent({
        componentType,
        component: edited,
      }),
    );
    if (!original || !original.id) {
      setIsAdding(false);
    }
  };

  const overwriteMarketMarkPrices = async () => {
    try {
      /** @type {import('../scenarios/scenarioSlice').Market[]} */
      const marketPrices = await pricesTrigger({
        date: toIsoDate(marketDate),
        priceSource: priceSource,
        endpoint: 'mtm_marks/',
        bidAsk: priceType === 'BID_ASK',
      }).unwrap();

      const scenarioMarkets = markets.filter(
        (market) => market.price_source !== 'MARK_TO_MARKET',
      );
      const marks = marketPrices.filter((market) => market.base_price !== null);

      dispatch(
        scenarioActions.overwrite({
          componentType,
          components: [...marks, ...scenarioMarkets],
          applySettings: true,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const overwriteMarketPrices = async () => {
    try {
      /** @type {import('../scenarios/scenarioSlice').Market[]} */
      const marketPrices = await pricesTrigger({
        date: toIsoDate(marketDate),
        priceSource: priceSource,
        strip: strip,
        bidAsk: priceType === 'BID_ASK',
      }).unwrap();

      const marks = markets.filter(
        (market) => market.price_source === 'MARK_TO_MARKET',
      );

      dispatch(
        scenarioActions.overwrite({
          componentType,
          components: [
            ...marketPrices.filter((market) => market.base_price !== null),
            ...marks,
          ],
          applySettings: true,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const removeItem = (id) => {
    dispatch(
      scenarioActions.removeComponent({ componentType, componentId: id }),
    );
  };

  /**
   *
   * @param {import('../scenarios/scenarioSlice').PointConstraint} constraint
   */
  const restoreItem = (constraint) => {
    dispatch(
      scenarioActions.restoreRemovedComponent({
        componentType,
        component: constraint,
      }),
    );
  };

  const bulkRemove = (ids) => {
    dispatch(scenarioActions.bulkDelete({ componentType, componentIds: ids }));
  };

  const ignoredMarketsRows = useMemo(
    () =>
      ignoredMarkets.map((market) => (
        <tr
          style={{ borderLeft: `5px solid ${colors.matteRed}` }}
          className="table-danger"
          key={`ignored-segment-constraint-${market.id}`}
        >
          <td></td>
          <td>{market.point}</td>
          <td>{market.description}</td>
          <td>{market.buy_sell}</td>
          <td colSpan={3}>{priceFormatter.format(market.price)}</td>
          <td>
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => restoreItem(market)}
            >
              Restore Market
            </button>
          </td>
        </tr>
      )),
    [ignoredMarkets.map((c) => c.id).join(',')],
  );

  const belowHeader = isEditing && ignoredMarkets.length > 0 && (
    <>
      <tr>
        <td className="position-relative" colSpan={columns.length + 2}>
          <div className="position-relative m-3">
            <div className="progress" style={{ height: 1 }}></div>
            <button
              type="button"
              className="position-absolute top-0 start-50 translate-middle btn btn-sm btn-secondary rounded-pill"
              style={{ height: '2rem' }}
              onClick={() => setShowIgnored((p) => !p)}
            >
              {showIgnored ? 'Hide' : 'Show'} {ignoredMarkets.length} ignored
              market
              {ignoredMarkets.length > 1 && 's'}
            </button>
            {showIgnored ? (
              <button
                type="button"
                className="position-absolute top-0 end-0 translate-middle btn btn-sm btn-danger rounded-pill"
                style={{ height: '2rem' }}
                onClick={() => {
                  ignoredMarkets.map((item) => restoreItem(item));
                }}
              >
                Restore All Markets
              </button>
            ) : (
              ''
            )}
          </div>
        </td>
      </tr>
      {showIgnored && ignoredMarketsRows}
    </>
  );

  /**
   *
   * @param {import('../scenarios/scenarioSlice').Market} item
   * @returns {React.CSSProperties}
   */
  const getRowStyle = (item) => {
    if (addedMarkets.includes(marketKeyExtractor(item))) {
      return { borderLeft: `5px solid ${colors.matteGreen}` };
    }
    if (updatedMarkets.includes(marketKeyExtractor(item))) {
      return { borderLeft: `5px solid ${colors.matteYellow}` };
    }
    return {};
  };

  /**
   *
   * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
   */
  const confirmImport = (data) => {
    setImportModalOpen(false);
    /** @type {import('../scenarios/scenarioSlice').Market[]} */
    const markets = data
      .filter((row) => {
        return REQUIRED_COLUMNS.every((col) => row[col].value !== '');
      })
      .map((row) => {
        const price = parseFloat(row[COLUMNS.PRICE].value);
        return {
          point: parseInt(row[COLUMNS.POINT].value),
          description: row[COLUMNS.DESCRIPTION].value,
          buy_sell: String(row[COLUMNS.BUY_SELL].value).toUpperCase(),
          max_volume: parseFloat(row[COLUMNS.MAX_VOLUME].value) ?? 10000,
          price,
          base_price: price,
          adder: 0,
          price_source: 'USER_DEFINED',
          price_explanation: 'User defined price',
        };
      });
    dispatch(
      scenarioActions.appendComponents({
        componentType,
        components: markets,
        saveSetting: true,
      }),
    );
  };

  /**
   *
   * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
   */
  const confirmOverwrite = (data) => {
    setImportModalOpen(false);
    /** @type {import('../scenarios/scenarioSlice').Market[]} */
    const markets = data
      .filter((row) => {
        return REQUIRED_COLUMNS.every((col) => row[col].value !== '');
      })
      .map((row) => {
        const price = parseFloat(row[COLUMNS.PRICE].value);
        return {
          point: parseInt(row[COLUMNS.POINT].value),
          description: row[COLUMNS.DESCRIPTION].value,
          buy_sell: String(row[COLUMNS.BUY_SELL].value).toUpperCase(),
          max_volume: parseFloat(row[COLUMNS.MAX_VOLUME].value) ?? 10000,
          price,
          base_price: price,
          adder: 0,
          price_source: 'USER_DEFINED',
          price_explanation: 'User defined price',
        };
      });
    dispatch(
      scenarioActions.overwrite({
        componentType,
        components: markets,
        saveSetting: true,
      }),
    );
  };

  return (
    <>
      {isEditing && (
        <>
          <div
            className="row justify-content-between m-3 w-100"
            style={{ flexWrap: 'nowrap' }}
          >
            <div className="col-6 col-md-8 col-sm-12">
              <DateSelector
                value={toIsoDate(marketDate)}
                onChange={(evt) => setMarketDate(evt.target.valueAsDate)}
                isLoading={fetchingPrices || loadingPrices}
                onConfirm={overwriteMarketPrices}
                buttonText="Get Markets"
              />
            </div>
            <div className="col-auto d-flex gap-2">
              <button
                className="btn btn-outline-primary"
                onClick={() => overwriteMarketMarkPrices()}
              >
                Get Marks
              </button>
              <button
                onClick={() => setImportModalOpen(true)}
                className="btn btn-primary"
              >
                Spreadsheet View
              </button>
              <button
                onClick={() => setIsAdding((prev) => !prev)}
                className="btn btn-success"
              >
                {isAdding ? 'Cancel' : 'Add Markets'}
              </button>
            </div>
          </div>
          {simulateSpread && (
            <div className="row justify-content-between m-3">
              <div className="col-12">
                <p className="text-center alert alert-primary">
                  The scenario is currently set to simulate spreads, it will
                  assume fixed prices for receipt/delivery locations. <br />
                  The prices are fixed in a way that transport costs are always
                  covered, resulting in all possible paths to a point being
                  found even though actual prices may not be profitable. <br />
                  In order to edit the prices, unmark the {'"'}
                  <i>Simulate Spread</i>
                  {'"'} checkbox.
                </p>
              </div>
            </div>
          )}
        </>
      )}
      <EditableTable
        getRowStyle={getRowStyle}
        columns={columns}
        data={listedMarkets}
        belowHeader={belowHeader}
        keyExtractor={marketKeyExtractor}
        isEditing={isEditing}
        isEditable={true}
        isAdding={isAdding}
        onBulkRemove={bulkRemove}
        onSaveBulkEdit={saveBulkEdit}
        onSaveItem={saveItem}
        onDeleteItem={removeItem}
        onCancelCreation={() => setIsAdding(false)}
        emptyText="No Markets"
      />
      <SpreadsheetModal
        ref={sheetRef}
        isOpen={importModalOpen}
        toggle={() => setImportModalOpen((prev) => !prev)}
        title="Import Markets"
        subTitle={<p className="text-muted">Required fields: *</p>}
        data={[]}
        readonlyColumns={[COLUMNS.LOC_NAME]}
        sheetProps={{
          onChange: onChangeSpreadsheetDebounce,
          columnLabels: Object.keys(COLUMNS_LABELS).map((col) => {
            return `${COLUMNS_LABELS[col]}${
              REQUIRED_COLUMNS.includes(Number(col)) ? ' *' : ''
            }`;
          }),
        }}
        fileName="test.xlsx"
        sheetName="test"
      >
        <button
          className="btn btn-danger"
          onClick={() => {
            sheetRef.current?.setData([]);
          }}
        >
          Clear
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            const scenarioMarkets = markets.map((market) => [
              { value: market.point },
              { value: allPoints[market.point].loc_name },
              { value: market.description },
              { value: market.buy_sell },
              { value: market.max_volume },
              { value: market.price },
            ]);
            const newMarkets = [
              ...sheetRef.current
                .getData()
                .filter((row) => row[COLUMNS.POINT].value !== ''),
              ...scenarioMarkets,
            ];
            sheetRef.current?.setData(newMarkets);
          }}
        >
          Pull From Scenario
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            confirmOverwrite(sheetRef.current.getData());
            sheetRef.current.clear();
          }}
        >
          Overwrite
        </button>
        <button
          className="btn btn-success"
          onClick={() => {
            confirmImport(sheetRef.current.getData());
            sheetRef.current.clear();
          }}
        >
          Confirm Import
        </button>
      </SpreadsheetModal>
    </>
  );
};
