import { useSelector } from 'react-redux';
import {
  selectAllPoints,
  selectFilteredEdgeNodes,
} from '../plotter/plotterSelectors';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON.js';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { WebGLLayer } from './webglLayer';
import { mapContext } from './EdgeMap';
import { fromLonLat } from 'ol/proj';
import {
  selectFilteredIOCContracts,
  selectIOCVisibility,
} from '../ioc/iocSlice';
import { IOCSegmentModal } from '../ioc/IOCSegmentModal';

const getPathStyle = () => ({
  'stroke-width': ['get', 'width'],
  'stroke-color': ['get', 'color'],
  'stroke-line-cap': 'round',
  'stroke-line-join': 'round',
  'stroke-opacity': 0.8,
});

export const MapIOCPaths = ({ zIndex }) => {
  const { map, registerClickListener, unregisterClickListener } =
    useContext(mapContext);
  const nodes = useSelector(selectFilteredEdgeNodes);
  const allPoints = useSelector(selectAllPoints);
  const iocContracts = useSelector(selectFilteredIOCContracts);
  const visibility = useSelector(selectIOCVisibility);

  /**
   * @type {import('../types/types').State<string | null>}
   */
  const [modalKey, setModalKey] = useState(null);

  /** @type {import('./EdgeMap').MapEventListener} */
  const clickListener = useCallback(
    (feature, _) => {
      if (!feature || !feature?.getProperties()?.key?.startsWith('ioc-'))
        return;
      if (feature?.getProperties()?.key === `ioc-${modalKey}`) return;

      const lookup = feature.getProperties()?.id;
      if (feature?.getProperties()?.type === 'edge') {
        setModalKey(lookup);
      }
    },
    [modalKey],
  );

  const contractTypeColors = {
    FT: 'hsl(60,100%,75%)',
    IT: 'hsl(200,100%,75%)',
  };

  const order = {
    FT: 0,
    IT: 1,
  };

  const iocPathsLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features: iocContracts
          .filter((contract) => visibility[contract.id])
          .slice()
          .sort(
            (a, b) => order[b.rate_schedule_type] - order[a.rate_schedule_type],
          )
          .flatMap((record) => {
            const pathNodes = record.path_node_ids
              .map(([from, to]) => [nodes[from], nodes[to]])
              .filter(([from, to]) => from && to);

            return pathNodes.map(([from, to]) => {
              return {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: [from, to].map((node) =>
                    fromLonLat([node.lng, node.lat]),
                  ),
                },
                properties: {
                  id: `${from.id}-${to.id}`,
                  key: `ioc-${from.id}-${to.id}`,
                  type: 'edge',
                  width: 12,
                  color: contractTypeColors[record.rate_schedule_type ?? 'IT'],
                },
              };
            });
          }),
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getPathStyle(),
      zIndex,
    });
  }, [iocContracts, visibility, nodes]);

  useEffect(() => {
    if (!map || !iocPathsLayer) return;
    map.addLayer(iocPathsLayer);
    return () => {
      map.removeLayer(iocPathsLayer);
    };
  }, [map, iocPathsLayer]);

  useEffect(() => {
    if (!map) return;
    registerClickListener(clickListener);
    return () => {
      unregisterClickListener(clickListener);
    };
  }, [map, clickListener]);

  const modalInfo = useMemo(() => {
    if (!modalKey) return {};

    const [from, to] = modalKey.split('-');
    const fromNode = nodes[from];
    const toNode = nodes[to];
    const fromPoint = allPoints[fromNode?.points[0]];
    const toPoint = allPoints[toNode?.points[0]];
    const tsp = fromPoint?.tsp_name ?? toPoint?.tsp_name;
    return { fromPoint, toPoint, tsp };
  }, [modalKey, nodes, allPoints]);

  return (
    <>
      <IOCSegmentModal
        key={modalKey}
        segmentKey={modalKey}
        onClose={() => setModalKey(null)}
        tspName={modalInfo.tsp}
        fromPoint={modalInfo.fromPoint}
        toPoint={modalInfo.toPoint}
      />
    </>
  );
};
