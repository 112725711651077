import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { fromLonLat } from 'ol/proj.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllPoints,
  selectFilteredNodes,
} from '../plotter/plotterSelectors';
import * as selectedItemsActions from '../selectedItems/selectedItemsSlice';
import { Vector as VectorSource } from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { WebGLLayer } from './webglLayer';
import { getNodeColor } from '../utils/colors';
import { mapContext } from './EdgeMap';
import { MapTooltip } from './MapTooltip';
import { MapPopup } from './MapPopup';
import { NodePopup } from '../nodes/NodePopup';
import {
  selectEditState,
  selectMarkets,
  selectPointConstraints,
  selectScenario,
  selectTrades,
} from '../scenarios/scenarioSlice';
import { selectActivePath } from '../paths/pathsSlice';
import { NodeTooltip } from '../nodes/NodeTooltip';
import { selectPlotterMarkets } from '../plotter/plotterSlice';

/**
 *
 * @param {number} size
 * @returns {import('ol/style/flat').Rule}
 */
const getNodeStyle = (size) => [
  {
    filter: ['<=', ['get', 'compressor'], 0],
    'circle-radius': size,
    'circle-fill-color': ['get', 'color'],
    'circle-rotate-with-view': false,
    'circle-displacement': [0, 0],
    'circle-opacity': 0.8,
  },
  {
    filter: ['>', ['get', 'compressor'], 0],
    'shape-points': 4,
    'shape-radius': size * 1.6,
    'shape-fill-color': '#ffffff',
    'shape-stroke-color': ['get', 'compressorStroke'],
    'shape-stroke-width': 2,
    'shape-rotate-with-view': false,
    'shape-displacement': [0, 0],
    'shape-opacity': 1,
    'shape-angle': Math.PI / 4,
  },
];

/**
 *
 * @param {import('../services/edgeApi').Node} node
 * @param {Object<string, import('../services/edgeApi').Point>} points
 * @returns {boolean}
 */
const isCompressor = (node, points) => {
  return node.points.some((pointId) => points[pointId]?.loc_type_ind === 'COM');
};

/**
 *
 * @param {import('../services/edgeApi').Node} node
 * @param {string} color
 * @param {number} offset
 * @param {Object<string, import('../services/edgeApi').Point>} [points]
 */
const nodeToGEOJson = (node, color, points = {}) => {
  const isCs = isCompressor(node, points);
  const verified =
    isCs &&
    node.points
      .map((pointId) => points[pointId])
      .filter(Boolean)
      .every((point) => point.loc_type_ind !== 'COM' || point.verified);
  const geometry = {
    type: 'Point',
    coordinates: fromLonLat([Number(node.lng ?? 0), Number(node.lat ?? 0)]),
  };

  return {
    type: 'Feature',
    geometry,
    properties: {
      id: node.id,
      key: `node-${node.id}`,
      compressor: isCs ? 1 : 0,
      color: color,
      compressorStroke: verified ? '#2d8d00' : '#790800',
    },
  };
};

export const MapNodes = ({ zIndex }) => {
  const dispatch = useDispatch();
  const {
    map,
    registerHoverListener,
    unregisterHoverListener,
    registerClickListener,
    unregisterClickListener,
  } = useContext(mapContext);
  const nodes = useSelector(selectFilteredNodes);
  const points = useSelector(selectAllPoints);
  const nodeSize = useSelector((state) => Number(state.plotter.nodeSize));
  const activelySelectedNodeIds = useSelector(
    selectedItemsActions.selectActivelySelectedNodeIds,
  );

  const pointConstraints = useSelector(selectPointConstraints);
  const markets = useSelector(selectMarkets);
  const trades = useSelector(selectTrades);
  const plotterMarkets = useSelector(selectPlotterMarkets);
  const path = useSelector(selectActivePath);
  const scenario = useSelector(selectScenario);

  const marketsAndTrades = useMemo(
    () => [...markets, ...plotterMarkets, ...trades],
    [markets, trades, plotterMarkets],
  );

  const isEditing = useSelector(selectEditState);
  const isScenarioPage = location.pathname.match(/\/scenarios\/\d+$/);

  /** @type {import('./MapPopup').MapPopupRef} */
  const popupRef = useRef(null);

  /** @type {import('../types/types').State<import('../services/edgeApi').Node>} */
  const [tooltipNode, setTooltipNode] = useState(null);

  /** @type {import('../types/types').State<import('../services/edgeApi').Node>} */
  const [popupNode, setPopupNode] = useState(null);

  const [showDetailedView, setShowDetailedView] = useState(
    map?.getView().getZoom() ?? 0 > 8,
  );

  const selectedPoint = useSelector(selectedItemsActions.selectActivePoint);

  /** @type {import('./EdgeMap').MapEventListener} */
  const hoverListener = useCallback(
    (feature) => {
      if (feature?.getProperties()?.key === `node-${tooltipNode?.id}`) return;
      if (!feature || !feature?.getProperties()?.key?.startsWith('node-')) {
        setTooltipNode(null);
        return;
      }
      const nodeId = feature.getProperties()?.id;
      const node = nodes?.[nodeId];
      setTooltipNode(node);
    },
    [nodes],
  );

  const closePopup = useCallback(() => {
    setPopupNode(null);
    popupRef.current.hide();
  }, [popupRef, setPopupNode]);

  /** @type {import('./EdgeMap').MapEventListener} */
  const clickListener = useCallback(
    (feature, _coordinate) => {
      const nodeId =
        feature && feature.getProperties()?.key?.startsWith('node-')
          ? feature.getProperties()?.id
          : null;
      const node = nodes?.[nodeId];
      if (node) {
        popupRef.current.show(fromLonLat([Number(node.lng), Number(node.lat)]));
        dispatch(selectedItemsActions.markerClicked(node.id));
        setPopupNode(node);
      } else popupRef.current.hide();
    },
    [nodes],
  );

  useEffect(() => {
    if (selectedPoint) {
      const node = nodes[selectedPoint.node];
      if (!node) return;
      setPopupNode(node);
      popupRef.current.show(fromLonLat([Number(node.lng), Number(node.lat)]));
    }
  }, [selectedPoint]);

  const nodesLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features:
          nodeSize > 0
            ? Object.values(nodes)
                .filter((node) => {
                  if (Boolean(node) === false) return false;
                  return showDetailedView || isCompressor(node, points);
                })
                .map((node) =>
                  nodeToGEOJson(
                    node,
                    getNodeColor(node, points, activelySelectedNodeIds),
                    points,
                  ),
                )
            : [],
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getNodeStyle(nodeSize),
      zIndex,
    });
  }, [nodes, points, nodeSize, showDetailedView]);

  useEffect(() => {
    registerHoverListener(hoverListener);
    return () => {
      unregisterHoverListener(hoverListener);
    };
  }, [hoverListener, registerHoverListener, unregisterHoverListener]);

  useEffect(() => {
    registerClickListener(clickListener);
    return () => {
      unregisterClickListener(clickListener);
    };
  }, [clickListener, registerClickListener, unregisterClickListener]);

  useEffect(() => {
    if (!map || !nodesLayer) return;
    map.addLayer(nodesLayer);
    const onChangeZoom = () => {
      const _showDetailedView = map.getView().getZoom() > 8;
      if (showDetailedView !== _showDetailedView) {
        setShowDetailedView(_showDetailedView);
      }
    };
    map.getView().on('change:resolution', onChangeZoom);
    return () => {
      map.removeLayer(nodesLayer);
      map.un('change:resolution', onChangeZoom);
      nodesLayer.dispose();
    };
  }, [map, nodesLayer]);

  const tooltipPoints = useMemo(() => {
    if (!tooltipNode) return [];
    return tooltipNode.points.map((pointId) => points[pointId]).filter(Boolean);
  }, [tooltipNode, points]);

  const constraintsOnPoint = useMemo(() => {
    return Object.values(pointConstraints).filter((constraint) =>
      tooltipNode?.points.includes(constraint.point),
    );
  }, [pointConstraints, tooltipNode]);

  const pricesOnPoint = useMemo(() => {
    return marketsAndTrades.filter((market) =>
      tooltipNode?.points.includes(market.point),
    );
  }, [marketsAndTrades, tooltipNode]);

  const operations = useMemo(() => {
    if (!isScenarioPage || isEditing || !path) return {};
    if (!path.operation_chain) {
      console.warn(`Could not find operations for path ${path.id}`);
      return {};
    }
    const pathOperations = path.operation_chain.chain_links
      .filter((chain_link) => chain_link.has_flow == true)
      .map((chain_link) => {
        // this is basically copied from ChainDetails, maybe it could be extracte for reuse
        const operation = scenario.operations.find(
          (op) => op.id === chain_link.operation_id,
        );
        return {
          ...operation,
          chain_link,
        };
      });

    return pathOperations.reduce((acc, op) => {
      if (!acc[op.delivery_point.node]) {
        acc[op.delivery_point.node] = [];
      }
      acc[op.delivery_point.node].push({
        description: op.chain_link.delivery_description,
        type: 'Delivery',
        index: op.chain_link.order,
      });

      if (!acc[op.receipt_point.node]) {
        acc[op.receipt_point.node] = [];
      }
      acc[op.receipt_point.node].push({
        description: op.chain_link.receipt_description,
        type: 'Receipt',
        index: op.chain_link.order,
      });
      return acc;
    }, {});
  }, [path, scenario, isScenarioPage, isEditing]);

  return (
    <>
      <MapTooltip visible={Boolean(tooltipNode)}>
        <NodeTooltip
          node={tooltipNode}
          points={tooltipPoints}
          prices={pricesOnPoint}
          constraints={constraintsOnPoint}
          operations={operations[tooltipNode?.id] ?? []}
          isScenarioPage={isScenarioPage}
          isEditing={isEditing}
        />
      </MapTooltip>
      <MapPopup ref={popupRef} onClose={closePopup} autoPan={true}>
        <NodePopup node={popupNode} />
      </MapPopup>
    </>
  );
};
