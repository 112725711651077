/**
 *
 * @param {{
 *  pointId: import('../services/edgeApi').Point;
 *  theme: import('./hooks').Theme;
 * }} props
 * @returns
 */
export const PointBadge = ({ point, theme = 'dark' }) => {
  if (!point) {
    return null;
  }
  return (
    <div
      style={{
        borderRadius: 5,
        fontSize: 12,
        backgroundColor: theme === 'dark' ? '#212629' : 'lightgrey',
      }}
      className="m-1 p-1 text-center fw-bold"
      key={point.id}
    >
      {point.delivery_zone_name}: {point.loc_name}
    </div>
  );
};
