import React from 'react';
import Select from 'react-select';
import { CircleCounter } from './CircleCounter';
import { useTheme } from './hooks';

/**
 * @template {any} T
 * @typedef {{
 *  title: string,
 *  options: T[],
 *  markedFirst?: boolean,
 *  isMarked: (option: T) => boolean,
 *  onToggle: (option: T, checked: boolean) => void,
 *  optionRenderer: (option: T) => React.ReactNode,
 *  keyExtractor: (option: T) => string,
 *  allowAll?: boolean,
 *  onToggleAll?: (checked: boolean) => void,
 * }} MultiSelectProps<T>
 */

/**
 * @template {any} T
 * @param {MultiSelectProps<T> & {
 *  className?: string,
 *  style?: React.CSSProperties,
 * }} props
 * @returns {React.FC<MultiSelectProps<T>>}
 */
export const MultiSelectButton = ({
  title,
  options,
  markedFirst,
  keyExtractor,
  isMarked,
  onToggle,
  optionRenderer,
  className,
  style,
}) => {
  const counter = options.filter(isMarked).length;
  return (
    <>
      <button
        className={`btn btn-light dropdown-toggle justify-content-between ${className}`}
        role="button"
        id="filters-delivery-dropdown-button"
        data-bs-auto-close="outside"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={style}
      >
        <span className="me-2">{title}</span>
        {counter > 0 && <CircleCounter count={counter} />}
      </button>
      <ul
        style={{ maxHeight: '200px', overflowY: 'auto' }}
        className="dropdown-menu"
      >
        {options
          .slice()
          .sort((a, b) => (markedFirst ? isMarked(b) - isMarked(a) : 0))
          .map((option) => (
            <li key={keyExtractor(option)} className="dropdown-item">
              <div className="dropdown-item">
                <div className="form-check">
                  <label
                    className={`filter-delivery-keyExtractor(option)-check-label`}
                    htmlFor={`filter-delivery-keyExtractor(option)-check`}
                  >
                    {optionRenderer(option)}
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`filter-delivery-keyExtractor(option)-check`}
                    checked={isMarked(option)}
                    onChange={(e) => {
                      onToggle(option, e.target.checked);
                    }}
                  />
                </div>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};

/**
 * @template {any} T
 * @param {MultiSelectProps<T> & {
 *  name: string,
 * }} props
 * @returns {React.FC<MultiSelectProps<T>>}
 */
export const MultiSelectBox = ({
  title,
  name,
  options,
  markedFirst,
  allowAll = false,
  keyExtractor,
  isMarked,
  onToggle,
  onToggleAll,
  optionRenderer,
}) => {
  const counter = options.filter(isMarked).length;
  return (
    <>
      <div className="input-group">
        <span
          className="input-group-text"
          id={`multiselect-box-${name}-label`}
          htmlFor={`multiselect-box-${name}-input`}
        >
          <span className="me-2">{title}</span>
          {counter > 0 && <CircleCounter count={counter} />}
        </span>
        <ul
          id={`multiselect-box-${name}-dropdown`}
          style={{ maxHeight: '200px', overflowY: 'auto' }}
          className="dropdown-menu"
          aria-labelledby={`multiselect-box-${name}-input`}
        >
          {allowAll && (
            <li key="all" className="dropdown-item">
              <div className="form-check">
                <label
                  className={`filter-delivery-keyExtractor(option)-check-label`}
                  htmlFor={`filter-delivery-keyExtractor(option)-check`}
                >
                  All
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`filter-delivery-keyExtractor(option)-check`}
                  checked={counter === options.length}
                  onChange={(e) => onToggleAll?.(e.target.checked)}
                />
              </div>
            </li>
          )}
          {options
            .slice()
            .sort((a, b) => (markedFirst ? isMarked(b) - isMarked(a) : 0))
            .map((option) => (
              <li key={keyExtractor(option)} className="dropdown-item">
                <div className="form-check">
                  <label
                    className={`filter-delivery-keyExtractor(option)-check-label`}
                    htmlFor={`filter-delivery-keyExtractor(option)-check`}
                  >
                    {optionRenderer(option)}
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`filter-delivery-keyExtractor(option)-check`}
                    checked={isMarked(option)}
                    onChange={(e) => {
                      onToggle(option, e.target.checked);
                    }}
                  />
                </div>
              </li>
            ))}
        </ul>
        <button
          id={`multiselect-box-${name}-input`}
          className="form-select"
          style={{
            maxLines: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          data-bs-auto-close="outside"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          href={`#multiselect-box-${name}-dropdown`}
        >
          {options.filter(isMarked).map((option, i) => {
            return (
              <span key={keyExtractor(option)}>
                {optionRenderer(option)}
                {i < counter - 1 && ', '}
              </span>
            );
          })}
        </button>
      </div>
    </>
  );
};

/**
 * @template {*} T
 * @typedef {{
 *  onChange: (value: import('react-select').MultiValue<T>, actionMeta: import('react-select').ActionMeta<T>) => void,
 *  options: Array<{value: T, label: string}>,
 *  value: import('react-select').MultiValue<T>,
 * }} MultiSelectSearchProps
 */

/**
 * @template {*} T
 * @type {React.FunctionComponent<Omit<React.SelectHTMLAttributes, 'onChange' | 'options' | 'value'> & MultiSelectSearchProps<T>>}
 */
export const MultiSelectSearch = ({ onChange, options, value, ...props }) => {
  const [appTheme] = useTheme();
  return (
    <Select
      {...props}
      value={value}
      onChange={onChange}
      isMulti
      options={options}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        colors:
          appTheme === 'light'
            ? {
                ...theme.colors,
              }
            : {
                ...theme.colors,
                primary: 'var(--bs-body-color)',
                primary75: 'var(--bs-body-color)',
                primary25: 'var(--bs-secondary-bg)',
                neutral0: 'var(--bs-body-bg)',
                neutral10: 'var(--bs-secondary-bg)',
                neutral20: 'var(--bs-tertiary-bg)',
                neutral30: 'var(--bs-secondary-color)',
                neutral50: 'var(--bs-body-color)',
                neutral60: 'var(--bs-body-color)',
                neutral80: 'var(--bs-body-color)',
                neutral90: 'var(--bs-body-color)',
              },
      })}
    />
  );
};
