import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import TileLayer from 'ol/layer/Tile.js';
import { XYZ } from 'ol/source';
import { fromLonLat, transformExtent } from 'ol/proj.js';
import { MapEdges } from './MapEdges';
import { MapNodes } from './MapNodes';
import { MapInterconnects } from './MapInterconnects';
import { MapChains } from './MapChains';
import { ZoomPath } from '../paths/ZoomPath';
import { MapContracts } from './MapContracts';
import { useSelector } from 'react-redux';
import {
  MapMajorPointConstraints,
  MapMajorSegmentConstraints,
  MapMinorSegmentConstraints,
  MapMinorPointConstraints,
} from './MapConstraints';
import { MapMarkets } from './MapMarkets';
import { MapFlows } from './MapFlows';
import { MapNotices } from './MapNotices';
import { boundingExtent } from 'ol/extent';
import { MapRisePaths } from './MapRisePaths';
import { MapOperationMarkers } from './MapOperationMarkers';
import { MapOverlays } from './MapOverlays';
import process from 'process';
import { MapAllPathsChains } from './MapAllPathsChains';
import { useTheme } from '../helpers/hooks';
import { MapIOCPaths } from './MapIOCPaths';

/**
 * @typedef {{
 *  (feature: import('ol/Feature').FeatureLike | undefined, coordinate: import('ol/coordinate').Coordinate) => void
 * }} MapEventListener
 */

/**
 * @typedef {{
 *  map?: Map,
 *  registerHoverListener: (listener: MapEventListener) => void,
 *  unregisterHoverListener: (listener: MapEventListener) => void,
 *  registerClickListener: (listener: MapEventListener) => void,
 *  unregisterClickListener: (listener: MapEventListener) => void,
 * }} MapContext
 */

const extent = transformExtent(
  [-127.3648, 0, -64.5165, 49.4554],
  'EPSG:4326',
  'EPSG:3857',
);

/**
 * @type {React.Context<MapContext>}
 */
export const mapContext = createContext({
  map: null,
  registerHoverListener: (_listener) => null,
  unregisterHoverListener: (_listener) => null,
  registerClickListener: (_listener) => null,
  unregisterClickListener: (_listener) => null,
});

const tileSources = {
  dark: new XYZ({
    attributions:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    url: `https://cartodb-basemaps-a.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png`,
  }),
  light: new XYZ({
    attributions:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    url: `https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png`,
  }),
};

export const EdgeMap = () => {
  const [theme] = useTheme();

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const containerRef = useRef(null);

  /** @type {[Map, React.Dispatch<React.SetStateAction<Map>>]} */
  const [map, setMap] = useState(null);

  /** @type {React.MutableRefObject<Set<MapEventListener>>} */
  const hoverListeners = useRef(new Set());
  /** @type {React.MutableRefObject<Set<MapEventListener>>} */
  const clickListeners = useRef(new Set());

  /** @type {(listener: MapEventListener) => void} */
  const registerHoverListener = useCallback((listener) => {
    hoverListeners.current.add(listener);
  }, []);

  /** @type {(listener: MapEventListener) => void} */
  const unregisterHoverListener = useCallback((listener) => {
    hoverListeners.current.delete(listener);
  }, []);

  /** @type {(listener: MapEventListener) => void} */
  const registerClickListener = useCallback((listener) => {
    clickListeners.current.add(listener);
  }, []);

  /** @type {(listener: MapEventListener) => void} */
  const unregisterClickListener = useCallback((listener) => {
    clickListeners.current.delete(listener);
  }, []);

  /** @type {import('../plotter/plotterSlice').MapView | null} */
  const mapView = useSelector((state) => state.plotter.mapView);

  /**
   * @type {import('ol/coordinate').Coordinate[] | null}
   */
  const mapFit = useSelector((state) => state.plotter.mapFit);

  const hoveredFeature = useRef(null);
  const clickedFeature = useRef(null);

  // Create map
  useEffect(() => {
    const _map = new Map({
      layers: [
        new TileLayer({
          source: tileSources[theme],
        }),
      ],
      view: new View({
        // projection: 'EPSG:3857',
        center: fromLonLat([-87, 33]),
        extent: extent,
        zoom: 6,
      }),
      target: containerRef.current,
    });
    const resizeHandler = () => _map.updateSize();
    containerRef.current?.addEventListener('resize', resizeHandler);
    setMap(_map);

    return () => {
      containerRef.current?.removeEventListener('resize', resizeHandler);
      _map.getAllLayers().forEach((layer) => layer.dispose());
      _map.dispose();
    };
  }, [containerRef]);

  useEffect(() => {
    if (!map) return;
    map.getLayers().forEach((layer) => {
      if (layer instanceof TileLayer) {
        layer.setSource(tileSources[theme]);
      }
    });
    map.render();
  }, [theme]);

  // Set listeners
  useEffect(() => {
    if (!map) return;

    map.on('pointermove', (evt) => {
      const pixel = map.getEventPixel(evt.originalEvent);
      const features = [];
      try {
        map.forEachFeatureAtPixel(
          pixel,
          (feature, layer) => {
            features.push({ feature, zIndex: layer.getZIndex() ?? 0 });
            return false;
          },
          {
            layerFilter: (layer) => layer && map.getAllLayers().includes(layer),
          },
        );
      } catch (e) {
        if (process.env.WALL === 'true') {
          console.warn('forEachFeatureAtPixel: layer verification failed', e);
        }
      }
      const feature = features.sort((a, b) => b.zIndex - a.zIndex)[0]?.feature;

      if (feature) {
        map.getTargetElement().style.cursor = 'pointer';
      } else if (evt.dragging) {
        map.getTargetElement().style.cursor = 'grabbing';
      } else {
        map.getTargetElement().style.cursor = 'grab';
      }

      if (evt.dragging) {
        return;
      }

      if (
        feature?.getProperties()?.key ==
        hoveredFeature.current?.getProperties()?.key
      ) {
        return;
      }
      hoverListeners.current.forEach((listener) => listener(feature, pixel));
      hoveredFeature.current = feature;
    });

    map.on('click', (_evt) => {
      /** @type {import('ol/MapBrowserEvent').default<MouseEvent>} */
      const evt = _evt;
      const pixel = map.getEventPixel(evt.originalEvent);
      const features = [];
      map.forEachFeatureAtPixel(pixel, (feature, layer) => {
        features.push({ feature, zIndex: layer.getZIndex() ?? 0 });
        return false;
      });
      const feature = features.sort((a, b) => b.zIndex - a.zIndex)[0]?.feature;
      clickListeners.current.forEach((listener) =>
        listener(feature, evt.coordinate),
      );
      clickedFeature.current = feature;
    });
  }, [map]);

  useEffect(() => {
    if (mapView && map) {
      map.getView().animate({
        center: fromLonLat([mapView.center[1], mapView.center[0]]),
        zoom: mapView.zoom,
        duration: 1000,
      });
    }
  }, [mapView]);

  useEffect(() => {
    if (map && mapFit) {
      map.getView().fit(boundingExtent(mapFit), {
        padding: [50, 50, 50, 50],
        duration: 1000,
      });
    }
  }, [mapFit]);

  map?.updateSize();

  let zIndex = 0;

  return (
    <div className="flex-grow-1" id="map-container" ref={containerRef}>
      <mapContext.Provider
        value={{
          map,
          registerHoverListener,
          unregisterHoverListener,
          registerClickListener,
          unregisterClickListener,
        }}
      >
        <ZoomPath />
        <MapOverlays zIndex={++zIndex} />
        <MapRisePaths zIndex={++zIndex} />
        <MapIOCPaths zIndex={++zIndex} />
        <MapContracts zIndex={++zIndex} />
        <MapEdges zIndex={++zIndex} />
        <MapInterconnects zIndex={++zIndex} />
        <MapChains zIndex={++zIndex} />
        <MapAllPathsChains zIndex={++zIndex} />
        <MapOperationMarkers zIndex={++zIndex} />
        <MapMarkets zIndex={++zIndex} />
        <MapMinorSegmentConstraints zIndex={++zIndex} />
        <MapMajorSegmentConstraints zIndex={++zIndex} />
        <MapFlows zIndex={++zIndex} />
        <MapNodes zIndex={++zIndex} />
        <MapMinorPointConstraints zIndex={++zIndex} />
        <MapMajorPointConstraints zIndex={++zIndex} />
        <MapNotices zIndex={++zIndex} />
      </mapContext.Provider>
    </div>
  );
};
