/**
 * Builds a query string from an object
 * @param {{
 *  [key: string]: string | string[] | number | number[] | boolean | boolean[] | null | undefined
 * }} params
 * @returns {string} Returns a string containing a query string suitable for use in a URL. Does not include the question mark.
 * @throws {Error} if params is not `object | null | undefined` or if any value is an object
 */
export const buildQueryString = (params = {}) => {
  if (params === null || params === undefined) return '';

  if (Array.isArray(params))
    throw new Error('params must be an object, not an array');
  if (typeof params !== 'object')
    throw new Error(`params must be an object, not a ${typeof params}`);

  const url = new URL('http://localhost');
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined) return;

    if (Array.isArray(params[key])) {
      params[key].forEach((value) => {
        if (value === null || value === undefined) return;
        url.searchParams.append(key, value);
      });
      return;
    }

    if (typeof params[key] === 'object') {
      throw new Error(
        `value cannot be an object, key: ${key}, value: ${JSON.stringify(
          params[key],
        )}`,
      );
    }

    url.searchParams.append(key, params[key]);
  });
  return url.searchParams.toString();
};

/**
 *
 * @param {Date} date the date to format
 * @returns {string} iso string containing only the date portion
 * @brief converts a date to an iso string containing only the date portion, if `date` is not a Date object, the current date is used
 */
export const toIsoDate = (date = new Date()) => {
  const type = Object.prototype.toString.call(date);
  if (type !== '[object Date]') {
    console.error(
      `toIsoDate: date must be a Date object, not a ${type}, defaulting to current date`,
    );
    return toIsoDate();
  }
  if (Number.isNaN(date.getTime())) {
    console.error(
      `toIsoDate: date must be a valid Date object, defaulting to current date`,
    );
    return toIsoDate();
  }
  return date.toISOString().split('T')[0];
};

/**
 * Replaces multiple linebreaks with a single linebreak
 * @param {string} str
 * @returns {string} str with all line breaks replaced with a single line break
 */
export const trimLineBreaks = (str) => {
  if (str === null || str === undefined) return '';
  if (typeof str !== 'string')
    throw new TypeError(`str must be a string, not a ${typeof str}`);

  return str.replace(/(\n|\r){2,}/gm, '\n');
};

/**
 *
 * @param {string} str
 * @returns {string} str with the first letter of each word capitalized
 */
export const titleize = (str) => {
  if (str === null || str === undefined) return '';
  if (typeof str !== 'string')
    throw new TypeError(`str must be a string, not a ${typeof str}`);

  return str
    .split(' ')
    .map(
      (word) => word[0].toUpperCase() + word.slice(1, undefined).toLowerCase(),
    )
    .join(' ');
};

/**
 *
 * @param {string} text
 * @param {string} url
 * @returns {string} html link element with the given text and url
 */
export const toLink = (text, url) => {
  if (text === null || text === undefined) return '';
  if (typeof text !== 'string')
    throw new TypeError(`text must be a string, not a ${typeof text}`);
  if (url === null || url === undefined || URL.canParse(url) === false)
    return '';
  if (typeof url !== 'string')
    throw new TypeError(`url must be a string, not a ${typeof url}`);

  const link = document.createElement('a');
  link.href = url;
  link.innerText = text;
  link.textContent = text;
  return link.outerHTML;
};

/**
 *
 * @param {string} str
 * @returns {number}
 */
export const hashString = (str) => {
  var hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 *
 * @param {import('../scenarios/scenarioSlice').Point} source
 * @param {import('../scenarios/scenarioSlice').Point} target
 * @param {import('../scenarios/scenarioSlice').SegmentConstraint} constraint
 * @returns {string}
 */
export const constraintToString = (source, target, constraint) => {
  const sourceName = source?.loc_name || source?.id.toString();
  const targetName = target?.loc_name || target?.id.toString();
  if (constraint.flow_direction === 'FORWARDHAUL') {
    return `From ${sourceName} to ${targetName}`;
  }
  return `From ${targetName} to ${sourceName}`;
};
