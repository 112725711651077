import { LoadingButton } from './LoadingButton';

/**
 *
 * @typedef {{
 *  value: string | number | Date,
 *  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void,
 *  onConfirm: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
 *  isLoading: boolean,
 *  buttonText: string,
 *  className: string,
 *  inputProps: React.HTMLProps<HTMLInputElement>
 *  label?: string
 * }} DateSelectorProps
 * @returns
 */

/**
 * @param {DateSelectorProps} props
 * @returns {React.FC<DateSelectorProps>}
 */
export function DateSelector({
  className,
  value,
  onChange,
  inputProps,
  onConfirm,
  isLoading,
  buttonText,
  label = 'Date',
}) {
  return (
    <div className={`input-group ${className}`}>
      <span className="input-group-text">{label}</span>
      <input
        value={value}
        onChange={onChange}
        type="date"
        className="form-control form-control-sm"
        {...inputProps}
      />
      {buttonText && (
        <LoadingButton
          className="btn btn-outline-primary"
          onClick={onConfirm}
          isLoading={isLoading}
        >
          {buttonText}
        </LoadingButton>
      )}
    </div>
  );
}
