import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectAllPoints } from '../plotter/plotterSelectors';

/**
 * @typedef {{
 *  id: number;
 *  tsp: {
 *    id: number;
 *    name: string;
 *  };
 *  calendar_quarter: string;
 *  owner: string;
 *  owner_id: string;
 *  owner_affiliation: string;
 *  agent_name: string;
 *  agent_affiliation: string;
 *  rate_schedule_type: string;
 *  k_id: string;
 *  start_date: string;
 *  end_date: string;
 *  max_daily_quantity: number;
 *  receipt_points: number[];
 *  delivery_points: number[];
 *  receipt_zones: number[];
 *  delivery_zones: number[];
 *  path_node_ids: Array<[number, number]>;
 * }} IOCContract
 */

/**
 * @typedef {{
 *  tsp: number[];
 *  rate_schedule_type: string[];
 *  owner: string[];
 *  agent_name: string[];
 *  type: string[];
 *  k_id: string;
 *  receipt_points: string;
 *  receipt_zones: int[];
 *  delivery_points: string;
 *  delivery_zones: int[];
 * }} IOCContractsFilter
 */

/**
 * @typedef {{
 *  contracts: IOCContract[];
 *  visibility: {[key: number]: boolean};
 *  filters: IOCContractsFilter;
 * }} IOCState
 */

// ================================ Initial State ================================ //

/**
 * @type {IOCState}
 */
const initialState = {
  contracts: [],
  visibility: {},
  filters: {
    tsp: [],
    owner: [],
    agent_name: [],
    rate_schedule_type: [],
    type: [],
    k_id: '',
    receipt_points: '',
    delivery_points: '',
    delivery_zones: [],
    receipt_zones: [],
  },
};

// ================================ Private Methods ================================ //

// ================================ Public Methods ================================ //

// ================================ Reducers ================================ //

/**
 * @param {IOCState} initialState
 */
const resetState = (initialState) => initialState;

/**
 *
 * @param {IOCState} state
 * @param {{payload: IOCContract[]}} action
 * @returns {void}
 */
const setContracts = (state, action) => {
  state.contracts = action.payload;
  state.visibility = action.payload.reduce((acc, contract) => {
    acc[contract.id] = true;
    return acc;
  }, {});
};

/**
 *
 * @param {IOCState} state
 * @param {{
 *  payload: {
 *    contracts: IOCContract[];
 *    visible: boolean;
 *  }
 * }} action
 */
const setVisibility = (state, action) => {
  const { contracts, visible } = action.payload;
  for (const contract of contracts) {
    state.visibility[contract.id] = visible;
  }
};

/**
 *
 * @param {IOCState} state
 * @param {{
 *  payload: {
 *    key: keyof IOCContractsFilter;
 *    value: string;
 *  }
 * }} action
 */
const setFilter = (state, action) => {
  const { key, value } = action.payload;
  state.filters[key] = value;
};

/**
 *
 * @param {IOCState} initialState
 */
const _iocReducers = (initialState) => ({
  resetState: () => resetState(initialState),
  setContracts,
  setVisibility,
  setFilter,
});

const reducers = _iocReducers(initialState);

/** @type {import('@reduxjs/toolkit').Slice<IOCState, typeof reducers, 'ioc'>} */
export const iocSlice = createSlice({
  name: 'ioc',
  initialState,
  reducers,
});

export const iocActions = iocSlice.actions;

// ================================ Selectors ================================ //

/**
 * @param {{
 *  ioc: IOCState;
 * }} state
 */
const selectState = (state) => state?.ioc ?? initialState;

export const selectIOCContracts = createSelector(
  selectState,
  (state) => state.contracts,
);

export const selectIOCVisibility = createSelector(
  selectState,
  (state) => state.visibility,
);

export const selectIOCFilters = createSelector(
  selectState,
  (state) => state.filters,
);

export const selectFilteredIOCContracts = createSelector(
  selectIOCContracts,
  selectIOCFilters,
  selectAllPoints,
  (contracts, filters, points) => {
    return contracts.filter((contract) => {
      return Object.entries(filters ?? {}).every(([key, value]) => {
        if (value === '' || (Array.isArray(value) && value.length === 0))
          return true;

        if (key === 'receipt_points' || key === 'delivery_points') {
          return contract[key]
            .map((point_id) => points[point_id]?.loc_name ?? '')
            .some((v) => v.toLowerCase().includes(value.toLowerCase()));
        }

        if (key === 'receipt_zones' || key === 'delivery_zones') {
          return filters[key].some((zoneId) => contract[key].includes(zoneId));
        }

        if (key === 'tsp') {
          return filters.tsp.some((tspId) => contract.tsp.id === tspId);
        }

        if (Array.isArray(value)) {
          return value.some((item) =>
            contract[key]
              ?.toString()
              .toLowerCase()
              .includes(item.toLowerCase()),
          );
        }

        return contract[key]
          ?.toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      });
    });
  },
);

export default iocSlice.reducer;
