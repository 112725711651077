import { CloseIcon } from '@iconicicons/react';
import { ReusableModal } from '../modals/ReusableModal';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { selectFilteredIOCContracts } from '../ioc/iocSlice';
import { PointBadge } from '../helpers/PointBadge';
import { useTheme } from '../helpers/hooks';
import { selectAllPoints } from '../plotter/plotterSelectors';

/**
 * @typedef {{
 *  segmentKey: string;
 *  onClose: () => void;
 *  tspName: string;
 *  fromPoint: import('../plotter/plotterSlice').Point;
 *  toPoint: import('../plotter/plotterSlice').Point;
 *  iocContracts: import('../ioc/iocSlice').IOCContract[];
 * }} IOCSegmentModalProps
 */

/**
 *
 * @param {IOCSegmentModalProps} props
 * @returns
 */
export const IOCSegmentModal = ({
  segmentKey,
  onClose,
  tspName,
  fromPoint,
  toPoint,
}) => {
  const [theme] = useTheme();
  const iocContracts = useSelector(selectFilteredIOCContracts);
  const allPoints = useSelector(selectAllPoints);

  const contractLookup = useMemo(
    () =>
      iocContracts.reduce(
        /**
         *
         * @param {{[key: string]: import('../ioc/iocSlice').IOCContract[]}} acc
         * @param {import('../ioc/iocSlice').IOCContract} contract
         * @returns
         */
        (acc, contract) => {
          contract.path_node_ids.forEach(([from, to]) => {
            const key = `${from}-${to}`;
            const reverseKey = `${to}-${from}`;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(contract);
            if (!acc[reverseKey]) {
              acc[reverseKey] = [];
            }
            acc[reverseKey].push(contract);
          });
          return acc;
        },
        {},
      ),
    [iocContracts],
  );

  /**
   * @type {Array<import('../ioc/iocSlice').IOCContract>}
   */
  const selectedContracts = useMemo(
    () => contractLookup[segmentKey] ?? [],
    [segmentKey, contractLookup],
  );

  const contractsByOwner = useMemo(() => {
    return selectedContracts.reduce(
      /**
       *
       * @param {{[key: string]: import('../ioc/iocSlice').IOCContract[]}} acc
       * @param {import('../ioc/iocSlice').IOCContract} contract
       * @returns
       */
      (acc, contract) => {
        if (!acc[contract.owner]) {
          acc[contract.owner] = [];
        }
        acc[contract.owner].push(contract);
        return acc;
      },
      {},
    );
  }, [selectedContracts]);

  return (
    <ReusableModal
      key={segmentKey}
      isOpen={segmentKey !== null}
      onClose={onClose}
      size="xl"
      scrollable
      header={
        <div className="d-flex flex-column gap-3">
          <div className="d-flex justify-content-between align-items-center">
            <CloseIcon role="button" onClick={onClose} />
          </div>
          <div>
            <h5>
              {tspName} zone{' '}
              {fromPoint?.receipt_zone_name ?? toPoint?.delivery_zone_name}
            </h5>
            <h4>
              From {fromPoint?.loc_name} to {toPoint?.loc_name}
            </h4>
          </div>
        </div>
      }
    >
      <div>
        <table className="table table-bordered">
          <thead
            style={{
              position: 'sticky',
              top: -17,
            }}
          >
            <tr>
              <th>Owner</th>
              <th>K ID</th>
              <th>Type</th>
              <th>Volume</th>
              <th>Receipt</th>
              <th>Delivery</th>
              <th>Start</th>
              <th>End</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(contractsByOwner).flatMap((owner) => {
              const contracts = contractsByOwner[owner] ?? [];
              return contracts?.map((contract, index) => (
                <tr key={contract.id}>
                  {index === 0 && (
                    <th scope="row" rowSpan={contracts.length}>
                      {contract.owner}
                    </th>
                  )}
                  <td>{contract.id}</td>
                  <td>{contract.rate_schedule_type}</td>
                  <td>{contract.max_daily_quantity}</td>
                  <td>
                    {contract.receipt_points.map((pointId) => (
                      <PointBadge
                        point={allPoints[pointId]}
                        key={pointId.toString()}
                        theme={theme}
                      />
                    ))}
                  </td>
                  <td>
                    {contract.delivery_points.map((pointId) => (
                      <PointBadge
                        point={allPoints[pointId]}
                        key={pointId.toString()}
                        theme={theme}
                      />
                    ))}
                  </td>
                  <td>{new Date(contract.start_date).toLocaleDateString()}</td>
                  <td>{new Date(contract.end_date).toLocaleDateString()}</td>
                </tr>
              ));
            })}
          </tbody>
        </table>
      </div>
    </ReusableModal>
  );
};
